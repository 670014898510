<template>
  <div>
    <el-dialog :title="isAdd ? '新增人员信息':'修改人员信息'" :visible.sync="dialogFormVisible" :append-to-body="true" :close-on-click-modal="false" @closed="cancel" width="640px">
      <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="84px" label-suffix=":" v-if="form" :disabled="disabled">
        <div class="h">
          <el-form-item label="姓名" prop="name" style="width: 50%;">
            <el-input v-model="form.name" :maxlength="15" />
          </el-form-item>
          <el-form-item label="性别" prop="sex" class="flex">
            <el-radio-group v-model="form.sex">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="0">女</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="h">
          <el-form-item label="手机号码" prop="phone" style="width: 50%;">
            <el-input v-model="form.phone" :maxlength="11" />
          </el-form-item>
          <el-form-item label="身份证号" prop="idcard" class="flex">
            <el-input v-model="form.idcard" :maxlength="18" />
          </el-form-item>
        </div>
        <div class="h">
          <el-form-item label="部门" prop="deptId" style="width: 50%;">
            <tree-picker v-model="form.deptId" url="api/dept" placeholder="请选择部门" style="width: 100%;" @change="form.jobId = null" />
          </el-form-item>
          <el-form-item label="岗位" prop="jobId" class="flex">
            <quick-select v-model="form.jobId" filterable :url="`api/job?deptId=${form.deptId}`" v-if="form.deptId" placeholder="请选择岗位" style="width:100%" />
            <el-input disabled placeholder="请先选择部门" v-else />
          </el-form-item>
        </div>
        <div class="h">
          <el-form-item label="编码" prop="employeeNo" style="width: 50%;">
            <el-input v-model="form.employeeNo" :maxlength="15" />
          </el-form-item>
          <el-form-item label="邮箱" prop="email" class="flex">
            <el-input v-model="form.email" :maxlength="20" />
          </el-form-item>
        </div>

        <div class="h">
          <el-form-item label="职务" prop="duties" style="width: 50%;">
            <quick-select v-model="form.duties" filterable url="api/dictDetail?dictName=duties" placeholder="请选择" style="width: 100%;" display-field="label" />
          </el-form-item>
          <el-form-item label="是否在职" class="flex">
            <el-switch v-model="form.status" :active-value="0" :inactive-value="1" />
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="doSave">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { add, edit } from "@/api/contact";
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      dialogFormVisible: false,
      disabled: false,
      form: null,
      rules: {
        name: [{ required: "true", message: "姓名为必填项", trigger: "blur" }],
        phone: [
          { required: "true", message: "手机号码为必填项" },
          { pattern: /^\d{11}$/gi, message: "错误的手机号码", trigger: "blur" }
        ],
        deptId: [
          { required: "true", message: "请选择部门", trigger: "change" }
        ],
        idcard: [
          {
            pattern: /^(\d{14}|\d{17})[\dx]$/gi,
            trigger: "blur",
            message: "身份证号格式不正确"
          }
        ]
      }
    };
  },
  methods: {
    cancel() {
      this.form = null;
    },
    // 点击保存判断是 新增 还是 编辑修改
    doSave() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          if (this.isAdd) {
            this.doAdd();
          } else this.doEdit();
        }
      });
    },
    // 确认保存
    doAdd() {
      add(this.form)
        .then(res => {
          this.dialogFormVisible = false;
          this.$notify({
            title: "保存人员信息成功",
            type: "success",
            duration: 2500
          });
          this.loading = false;
          this.$parent.init();
        })
        .catch(err => {
          this.loading = false;
          console.log(err.response.data.message);
        });
    },
    // 修改
    doEdit() {
      edit(this.form)
        .then(res => {
          this.dialogFormVisible = false;
          this.$notify({
            title: "保存人员信息成功",
            type: "success",
            duration: 2500
          });
          this.loading = false;
          this.$parent.init();
        })
        .catch(err => {
          this.loading = false;
          console.log(err.response.data.message);
        });
    },
    restForm(form) {
      this.dialogFormVisible = true;
      this.disabled = false;
      this.form = form || {
        name: null,
        sex: 1,
        phone: null,
        email: null,
        idcard: null,
        employeeNo: null,
        deptId: "",
        jobId: "",
        duties: null,
        status: 0
      };
    }
  }
};
</script>
